<template>
  <ShadowCard
    :param="{
      name: 'Edit Position',
    }"
    styleClass="vgt-table bordered striped">
    <b-overlay :show="isLoading">
      <div class="portfolioEdit accountTable">
        <vue-good-table
          :rows="rows"
          :columns="fields"
          styleClass="vgt-table"
          :pagination-options="{
            enabled: true,
          }">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.row.status == 'edited'">
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-if="
                props.column.editable == 'date' &&
                props.index == rowIndex &&
                editingMode
              ">
              <input
                class="form-control"
                v-model="editRow[props.column.field]"
                type="date"
                placeholder="Date" />
            </span>
            <span
              v-else-if="
                props.column.editable == 'number' &&
                props.index == rowIndex &&
                editingMode
              "
              class="editableCell"
              style="font-weight: bold; color: black">
              <b-form-input
                v-model="editRow[props.column.field]"
                :placeholder="props.column.label"
                step=".01"
                class="text-right inputNoBorder"
                type="text"
                v-cleave="numericFormat()"></b-form-input>
            </span>
            <span
              v-else-if="
                props.column.editable == 'money' &&
                props.index == rowIndex &&
                editingMode
              "
              class="editableCell"
              style="font-weight: bold; color: black">
              <b-form-input
                v-model="editRow[props.column.field]"
                :placeholder="props.column.label"
                step=".01"
                class="text-right inputNoBorder"
                type="text"
                v-cleave="numericFormatWithPrefix()"></b-form-input>
            </span>
            <span
              v-else-if="
                props.column.editable == 'assetSelect' &&
                props.index == rowIndex &&
                editingMode
              "
              class="editableCell overflowhiddencell">
              <v-select
                class="text-center editSelect"
                :options="securities"
                label="name"
                v-model="editRow[props.column.field]"
                ref="mySelect"
                :selectOnTab="true"
                :reduce="(object) => object.name">
                <template #selected-option="{ name, bbg_ticker }">
                  <span class="overflowSelect"
                    >{{ name }} ({{ bbg_ticker }})</span
                  >
                </template>
                <template #option="{ name, bbg_ticker }">
                  <span>{{ name }} ({{ bbg_ticker }})</span>
                </template>
              </v-select>
            </span>
            <span
              inline
              v-else-if="
                props.column.field == 'Actions' &&
                props.index == rowIndex &&
                editingMode
              "
              class="actionSpan"
              style="font-weight: bold; color: black; display: flex">
              <b-button
                @click="saveFn(props)"
                size="sm"
                class="bg-transparent bordertransparent"
                variant="light"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="black"
                  class="bi bi-check2"
                  viewBox="0 0 16 16">
                  <path
                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" /></svg
              ></b-button>
              <b-button
                @click="cancelEditFn()"
                size="sm"
                class="bg-transparent bordertransparent"
                variant="light"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-slash-circle"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
                </svg>
              </b-button>
            </span>
            <span
              class="actions"
              inline
              flex
              v-else-if="props.column.field == 'Actions'">
              <b-button
                @click="editFn(props)"
                size="sm"
                class="bg-transparent bordertransparent btn-edit"
                variant="light">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="var(--tableTheme)"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16">
                  <path
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </b-button>
              <b-button
                size="sm"
                @click="closeFn(props)"
                class="bg-transparent bordertransparent"
                variant="light"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="var(--tableTheme)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 3.5C13 2.94772 12.5523 2.5 12 2.5C11.4477 2.5 11 2.94772 11 3.5V4.0592C9.82995 4.19942 8.75336 4.58509 7.89614 5.1772C6.79552 5.93745 6 7.09027 6 8.5C6 9.77399 6.49167 10.9571 7.5778 11.7926C8.43438 12.4515 9.58764 12.8385 11 12.959V17.9219C10.2161 17.7963 9.54046 17.5279 9.03281 17.1772C8.32378 16.6874 8 16.0903 8 15.5C8 14.9477 7.55228 14.5 7 14.5C6.44772 14.5 6 14.9477 6 15.5C6 16.9097 6.79552 18.0626 7.89614 18.8228C8.75336 19.4149 9.82995 19.8006 11 19.9408V20.5C11 21.0523 11.4477 21.5 12 21.5C12.5523 21.5 13 21.0523 13 20.5V19.9435C14.1622 19.8101 15.2376 19.4425 16.0974 18.8585C17.2122 18.1013 18 16.9436 18 15.5C18 14.1934 17.5144 13.0022 16.4158 12.1712C15.557 11.5216 14.4039 11.1534 13 11.039V6.07813C13.7839 6.20366 14.4596 6.47214 14.9672 6.82279C15.6762 7.31255 16 7.90973 16 8.5C16 9.05228 16.4477 9.5 17 9.5C17.5523 9.5 18 9.05228 18 8.5C18 7.09027 17.2045 5.93745 16.1039 5.17721C15.2467 4.58508 14.1701 4.19941 13 4.0592V3.5ZM11 6.07814C10.2161 6.20367 9.54046 6.47215 9.03281 6.8228C8.32378 7.31255 8 7.90973 8 8.5C8 9.22601 8.25834 9.79286 8.79722 10.2074C9.24297 10.5503 9.94692 10.8384 11 10.9502V6.07814ZM13 13.047V17.9263C13.7911 17.8064 14.4682 17.5474 14.9737 17.204C15.6685 16.7321 16 16.1398 16 15.5C16 14.7232 15.7356 14.1644 15.2093 13.7663C14.7658 13.4309 14.0616 13.1537 13 13.047Z" />
                </svg>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="loadingContent"
            >&nbsp; <b-spinner type="border" label="Spinning"></b-spinner
          ></template>
          <template slot="emptystate">
            <div class="d-flex justify-content-center align-items-center h-100">
              No information available
            </div>
          </template>
        </vue-good-table>
      </div>
      <div class="row">
        <backButtonForExternalMethod @external-method="updatePortfolio()" />
      </div>
    </b-overlay>
  </ShadowCard>
</template>

<script>
import ShadowCard from "@/presentationalComponents/ShadowCard.vue";

import backButtonForExternalMethod from "@/components/buttons/backButtonForExternalMethod.vue";

import {
  getSecurities,
  editPortfolioPosition,
  getPortfolioPositionsByAssetId,
  getClientPositionsByAssetId,
  getPortfolioDetail,
} from "@/services/api.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PortfolioPositionEdit",
  components: { ShadowCard, backButtonForExternalMethod },
  data() {
    return {
      forceLoading: true,
      editingMode: false,
      securities: [],
      rowIndex: [],
      editRow: {},
      fields: [
        {
          label: "ID", //string que se muestra en la cima columna
          field: "Position_ID", // nombre de Propiedad que va en la columna
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle ",
          width: "75px",
        },
        {
          label: "Portfolio ID", //string que se muestra en la cima columna
          field: "Portfolio_ID", // nombre de Propiedad que va en la columna
          tdClass: "text-center align-middle",
          thClass: "vgt-center-align align-middle",
          width: "200px",
        },
        {
          label: "Trade Date",
          field: "Trade_Date",
          type: "string",
          width: "160px",
          tdClass: "text-center align-middle",
          editable: "date",
          thClass: "vgt-center-align align-middle",
        },
        {
          label: "Asset Name", //string que se muestra en la cima columna
          field: "Asset_Name", // nombre de Propiedad que va en la columna
          width: "300px",
          tdClass: "align-middle",
          thClass: "vgt-center-align align-middle ",
          editable: "assetSelect",
        },
        {
          label: "Ccy",
          field: "Currency",
          width: "75px",
          tdClass: "text-center align-middle",
          thClass: "vgt-center-align align-middle ",
        },
        {
          label: "Principal",
          field: "Principal",
          type: "number",
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle",
          editable: "number",
          formatFn: this.moneyFormat,
        },

        {
          label: "Paid Price",
          field: "Paid_Price",
          type: "number",
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle ",
          editable: "money",
          formatFn: this.priceFormat,
        },
        {
          label: "Mkt Price",
          field: "Market_Price",
          type: "number",
          tdClass: "text-right align-middle",
          thClass: "vgt-center-align align-middle",
          formatFn: this.priceFormat,
        },
        {
          label: "Mkt Value (USD)",
          field: "Market_Value_USD",
          type: "number",
          tdClass: "text-right  align-middle",
          thClass: "vgt-center-align align-middle ",
          formatFn: this.moneyFormat,
        },

        {
          field: "Actions",
          label: "Actions",
          thClass: "vgt-center-align align-middle",
          tdClass: "text-center",
          html: true,
        },
      ],
    };
  },
  mounted() {
    this.getPortfolioPosition();
    getSecurities().then((response) => (this.securities = response[0]));
    this.rows = [];
  },
  methods: {
    numericFormat() {
      return {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numeralDecimalScale: 6,
      };
    },
    numericFormatWithPrefix() {
      return {
        numeral: true,
        prefix: "$",
        numeralThousandsGroupStyle: "thousand",
        numeralDecimalScale: 6,
      };
    },
    updatePortfolio() {
      getPortfolioDetail(this.selectedPortfolio).then((res) => {
        let payload = res.data.data;
        payload.map((element) => (element.uuid = uuidv4()));
        this.$store.commit("SET_PORTFOLIODATA", payload);
      });
    },
    getPortfolioPosition() {
      const assetId = this.$route.params.asset_id;
      if (
        this.selectedPortfolioType.length > 0 &&
        this.selectedPortfolio.length > 0
      ) {
        if (this.selectedPortfolioType === "portfolio") {
          getPortfolioPositionsByAssetId(assetId, this.selectedPortfolio).then(
            (response) => {
              if (response.status === 200 || response.status === 201) {
                const payload = response.data.data.rows;
                this.rows = payload;
              } else {
                this.rows = [];
              }
              this.forceLoading = false;
            }
          );
        } else if (this.selectedPortfolioType === "client") {
          getClientPositionsByAssetId(assetId, this.selectedPortfolio).then(
            (response) => {
              if (response.status === 200 || response.status === 201) {
                const payload = response.data.data.rows;
                this.rows = payload;
              } else {
                this.rows = [];
              }
              this.forceLoading = false;
            }
          );
        }
      }
    },
    editFn(row) {
      this.$store.commit("SET_PORTFOLIOPOSITION_SELECTED", row.row.Position_ID);
      this.editRow = { ...row.row };
      this.editingMode = true;
      this.rowIndex = row.index;
    },
    closeFn(row) {
      this.$store.commit(
        "SET_CLOSINGFORMSELECTEDPORTFOLIO",
        row.row.Portfolio_ID
      );

      this.$store.commit("SET_CLOSINGFORMSELECTEDASSET", row.row.Asset_ID);
      this.$store.commit(
        "SET_CLOSINGFORMSELECTEDPOSITION",
        row.row.Position_ID
      );
      this.$router.push({ name: "Position Closing" });
    },
    cancelEditFn() {
      this.editingMode = false;
      this.rowIndex = "none";
    },

    filterNumber(value) {
      // Remove sign, comma, and dot from the value
      const filteredValue = value.replace(/[^\d.-]/g, "");
      return filteredValue;
    },
    saveFn(props) {
      // Itero sobre los fields
      this.fields.forEach((fieldObj) => {
        // Verifico si el objeto tiene la prop editable con valor 'money'
        if (fieldObj.editable === "money") {
          // Obtengo el nombre de la prop del objeto fieldObj
          const fieldName = fieldObj.field;
          // Verifico si la prop existe en props.formattedRow
          if (props.formattedRow.hasOwnProperty(fieldName)) {
            // Aplico la operación de 'replace' en el valor de la propiedad para quitar el signo de moneda
            this.editRow[fieldName] = this.filterNumber(
              this.editRow[fieldName]
            );
          }
        }
      });

      editPortfolioPosition(this.selectedPosition, { ...this.editRow })
        .then(() => {
          // Actualizar el portafolio y obtener las posiciones
          this.updatePortfolio();
          this.getPortfolioPosition();
        })
        .catch((error) => {});

      // Restablecer las variables
      this.editingMode = false;
      this.editRow = {};
    },

    moneyFormat(value) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      }).format(value);
    },
    priceFormat(value) {
      return new Intl.NumberFormat("en-US", {
        prefix: "$",
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      }).format(value);
    },
  },
  computed: {
    isLoading() {
      return this.forceLoading;
    },
    selectedPosition() {
      return this.$store.state.portfolio_position_selected;
    },
    selectedPortfolio() {
      return this.$store.state.selectedPortfolio;
    },
    selectedPortfolioType() {
      return this.$store.state.selectedPortfolioType;
    },
    rows: {
      get() {
        return this.$store.state.portfolio_position;
      },
      set(value) {
        this.$store.commit("SET_PORTFOLIOPOSITION", value);
      },
    },
  },
  watch: {
    selectedPortfolio() {
      this.editingMode = false;
    },
  },
};
</script>

<style>
.overflowhiddencell {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflowSelect {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
