<template>
  <div class="col-4">
    <button
      type="button"
      @click="handleButtonClick"
      class="btn btn-lg px-5 rounded-0 font-weight-bold btn-secondary">
      Back
    </button>
  </div>
</template>

<script>
export default {
  name: "backButton",
  methods: {
    handleButtonClick() {
      this.$emit("external-method");
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.btn-secondary {
  color: #343a40;
  font-size: 24px;
  border-width: 0px;
  background-color: white;
}

.btn-secondary:hover {
  color: white;
  font-size: 24px;
  border-width: 0px;
  background-color: #6c757d;
}
</style>
